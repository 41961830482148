<template>
    <div class="row dashboard">
        <div class="col-12">
            <div v-if="activeUser.id && !activeUser.is_verified" class="row gy-3 mb-3">
                <div  class="col-lg-12">
                    <user-email-verification />                   
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <b-card no-body class="card-animate overflow-hidden">
                        <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                                height="120">
                                <path id="Shape 8" class="s0"
                                    d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <b-card-body class="p-3" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Active Orders</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <count-to :startVal="0" :endVal="result.orders.active" :duration="5000"></count-to>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" :series='[getPercent(result.orders.active)]'
                                        :options="{ ...chartOptions }"></apexchart>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </div>

                <div class="col-md-6">
                    <b-card no-body class="card-animate overflow-hidden">
                        <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                                height="120">
                                <path id="Shape 8" class="s0"
                                    d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <b-card-body class="p-3" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3">In Progress</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <count-to :startVal="0" :endVal="result.deliveries.progress" :duration="5000"></count-to>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" :series='[getPercent(result.deliveries.progress)]'
                                        :options="{ ...chartOptions }"></apexchart>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </div>

                <div class="col-md-6">
                    <b-card no-body class="card-animate overflow-hidden">
                        <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                                height="120">
                                <path id="Shape 8" class="s0"
                                    d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <b-card-body class="p-3" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Won Orders</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <count-to :startVal="0" :endVal="result.deliveries.winner" :duration="5000"></count-to>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">

                                    <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" :series='[getPercent(result.deliveries.winner)]'
                                        :options="{ ...chartOptions, ...chartcolors }"></apexchart>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </div>
                <div class="col-md-6">
                    <b-card no-body class="card-animate overflow-hidden">
                        <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                                height="120">
                                <path id="Shape 8" class="s0"
                                    d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
                            </svg>
                        </div>
                        <b-card-body class="p-3" style="z-index:1 ;">
                            <div class="d-flex align-items-center">
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3"> Finalist Orders</p>
                                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                                        <count-to :startVal="0" :endVal="result.deliveries.finalist" :duration="5000"></count-to>
                                    </h4>
                                </div>
                                <div class="flex-shrink-0">
                                    <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" :series='[getPercent(result.deliveries.finalist)]'
                                        :options="{ ...chartOptions }"></apexchart>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </div>
            </div>
            <div class="row"> 
                <div class="col-12">
                    <div class="card overflow-hidden">
                        <div class="card-body bg-marketplace d-flex">
                            <div class="flex-grow-1">
                                <h4 class="fs-18 lh-base fw-bold mb-0"><span class="text-success">Summary</span></h4>
                                <p class="mb-0 mt-2 pt-1 text-muted">There is a total of <strong>{{result.orders.total}}</strong> order{{pluralize(result.orders.total)}} available in your account.
                                 <strong>{{result.orders.active}}</strong> {{pluralizeIsAre(result.orders.active)}} active, <strong>{{result.orders.delivered}}</strong> {{pluralizeIsAre(result.orders.delivered)}} delivered, and
                                  <strong>{{result.orders.completed}}</strong> {{pluralizeIsAre(result.orders.completed)}} completed. You have delivered on {{result.deliveries.total}} order{{pluralize(result.deliveries.total)}}. 
                                  <strong>{{result.deliveries.progress}}</strong> {{pluralizeIsAre(result.deliveries.progress)}} in progress. You were choosen as Finalist in 
                                  <strong>{{result.deliveries.finalist}}</strong> order{{pluralize(result.deliveries.finalist)}}. You won <strong>{{result.deliveries.winner}}</strong> order{{pluralize(result.deliveries.winner)}}. You were blocked on
                                   <strong>{{result.deliveries.blocked}}</strong> order{{pluralize(result.deliveries.blocked)}}, and eliminated in <strong>{{result.deliveries.eliminated}}</strong> order{{pluralize(result.deliveries.eliminated)}} </p>
                                <div class="d-flex gap-3 mt-4">
                                    <router-link class="btn btn-primary" to="/orders" >Orders </router-link>
                                    <router-link class="btn btn-success" to="/settings/profile" >Account</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"
import { CountTo } from "vue3-count-to";
import VueApexCharts from "vue3-apexcharts";

export default {
    name: 'dashboard',
    components:{
        apexchart: VueApexCharts,
        UserEmailVerification,
        CountTo,
    },
    data(){
        return{
            result: {
                "orders": {
                    "total": 0,
                    "active": 0,
                    "delivered": 0,
                    "completed": 0
                },
                "deliveries": {
                    "total": 0,
                    "progress": 0,
                    "finalist": 0,
                    "winner": 0,
                    "blocked": 0,
                    "eliminated": 0
                }
            },
            chartcolors: {
                colors: ["#f06548"]
            },
            chartOptions: {
                chart: {
                    type: "radialBar",
                    width: 105,
                    sparkline: {
                        enabled: true,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: '70%'
                        },
                        track: {
                            margin: 1
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                show: false
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontWeight: 600,
                                offsetY: 8,
                            }
                        }
                    },
                },
                colors: ["#0ab39c"]
            }
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
        pluralize(){
            return (i) => i > 1 ? 's':''
        },
        pluralizeIsAre(){
            return (i) => i > 1 ? 'are':'is'
        },
        getPercent(){
            return (val) => Math.round((val * 100) / this.result.orders.total) || 0
        }
    },
    created(){
        this.$http.get('/analytics')
            .then((response) => {
                if(response.data.success){
                    this.result = response.data.data.result;
                }
            })
    }
}
</script>

<style scoped>
.widget-pattern .s0 {
    opacity: .05;
    fill: var(--bs-success);
}
</style>
